
const URLS = {
	home: `/`,
	home2: `/home`,
	techs: `/techs`,
	contact: `/contact`,
	portfolio: `/portfolio`,
}

export default URLS
