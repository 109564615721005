export interface Store {
}

export const store: Store = {
     example: null,
     greenBox: { text: null, img: null, level: null },
     mailMe: false,
     showModal: false,
     textValue: '',
     inputValue: '',
     inputNameValue: ''
}
