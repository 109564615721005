export const COLORS = {
	ALERT_DANGER: '#B7202E',
	ALERT_SUCCESS: '#1AB162',
	ALERT_WARNING: '#FBAE2D',
	BLACK: 'black',
	GREEN_STRONG: '#29d658',
	GREEN: '#29d658',
	GREEN_2: '#29d65820',
	GREEN_3: '#29d981',
	GREEN_4: '#008924',
	GREEN_5: '#15956E',
	GREY_DEEP: '#3B4353',
	GREY_LIGHT: '#474F63',
	GREY_MEDIUM: '#A0A0A0',
	GREY_SMOOTH: '#2C323F',
	GREY_STRONG: '#3E4555',
	PURPLE: '#483856',
	GREY: '#D7D7D7',
	STRONG_BLUE: '#1F97AE',
	LIGHT_BLUE_STRONG: '#177183',
	LIGHT_BLUE: '#1F97AE',
	ORANGE: '#FA7321',
	PRIMARY: 'red',
	RED_STRONG: '#BE221A',
	RED: '#E32219',
	SECONDARY: 'accept',
	WHITE_GREY: '#F0F0F0',
	WHITE_SMOOTH: '#F9F9F9',
	WHITE: '#fff',
	YELLOW: '#FAC02C'
}